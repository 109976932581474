<template>
    <section id="sp-slider" class="sp-slider">
        <div :id="_id"
             class="sp-slider--container" 
             :style="`width: ${percent_total_items}%;`">

            <div v-for="(item, index) in items" :key="`item-${index}`"
                :style="`width: ${percent_item}%;`"
                style="height: 100%;">
                    <img :src="item" class="w-100" />
                    <!-- <slot :name="m_header" 
                        v-bind:item="data_item">

                            {{ m_header }}
                    </slot> -->
            </div>
        </div>

        <div :id="`footer_${_id}`" class="sp-slider--footer" v-show="show_dots">
            <div class="sp-slider-dot" 
                @click="changeShowItem(index)"
                v-for="(item, index) in items" :key="`dot-${index}`">
                    
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                item_current: null,
                autoplay_interval: null
            }
        },
        computed: {
            total_items() {
                return this.items.length
            },
            percent_total_items() {
                return 100 * this.total_items
            },
            percent_item() {
                return 100 / this.total_items
            }
        },
        props: {
            _id: {
                type: String,
                required: true,
                default: '',
            },
            items: {
                type: Array,
                required: true,
                default: '',
            },
            autoplay: {
                type: Boolean,
                required: false,
                default: true,
            },
            time_autoplay: {
                type: Number,
                required: false,
                default: 2000,
            },
            show_dots: {
                type: Boolean,
                required: false,
                default: true,
            }
        },
        methods: {
            changeShowItem(item) {
                //console.log('changeShowItem', item)

                this.item_current = item
                
                // Remove dots actives
                let dots = document.getElementById(`footer_${this._id}`).getElementsByClassName("sp-slider-dot")
                for(let i in dots) {
                    try {
                        dots[i].classList.remove("dot-active");    
                    }
                    catch(e) {}
                }
                // Set dot active
                dots[this.item_current].classList.add("dot-active");


                // Set item active
                let slider_container = document.querySelector(`#${this._id}`)
                let percent_current = (item == 0) ? 0 : (-1 * (this.percent_item * item))

                slider_container.style.transform = `translateX(${percent_current}%)`;
                //console.log(percent_current, 'percent_current')
            }
        },
        mounted() {
            this.item_current = 0
            this.changeShowItem(this.item_current)

            if(this.autoplay) {
                let This = this

                this.autoplay_interval = setInterval(function() {
                    if(This.item_current < This.total_items - 1) {
                        This.item_current = This.item_current + 1    
                    }
                    else {
                        This.item_current = 0
                    }

                    // ? :  
                    
                    This.changeShowItem(This.item_current)

                }, This.time_autoplay)
            }
        },
        unmounted() {
            clearInterval(this.autoplay_interval)
        }
    }
</script>

<style lang="scss" scoped>
    .sp-slider {
        width: 100%;
        overflow-x: hidden;
        min-height: 22vh;

        &--container {
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            transition: all .5s ease;
            transform: translateX(0%);
        }

        &--footer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            .sp-slider-dot {
                width: .7em;
                height: .7em;
                border-radius: 1em;
                background-color: rgb(204, 204, 204);
                margin: .6em;
                cursor: pointer;
            }
            .dot-active {
                background-color: #FF0000 !important;
            }
        }
    }
</style>
